body {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), 0.25);
}

/* --- CUSTOM SCROLL BAR - START --- */
/* width */
.json-output::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.json-output::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--bs-gray-900);
  background: var(--bs-secondary);
}

/* Handle */
.json-output::-webkit-scrollbar-thumb {
  background: var(--bs-gray-800);
  border-radius: 1.25px;
}

/* Handle on hover */
.json-output::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray-900);
}

.json-output::-webkit-scrollbar-corner {
  background: var(--bs-secondary);
  background: transparent;
  background: var(--bs-gray-800);
  border-radius: 50%;
}
/* --- CUSTOM SCROLL BAR - END --- */

.json-output {
  font-size: 0.625rem;
  background-color: transparent;
  border: none;
}

.json-output:focus {
  font-size: 0.625rem;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 0 0rem rgba(248, 249, 250, 0);
}

.icon-hover:hover svg {
  opacity: 80%;
}

.popover-arrow {
  display: none !important;
}

.image-popover {
  font-size: 0.75rem;
}

.image-popover img {
  box-shadow: 0px 0px 0.5rem 0.125rem rgba(0, 0, 0, 0.125);
}

.validation-feedback {
  color: var(--bs-form-invalid-color);
  border-color: var(--bs-form-invalid-border-color);
  text-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.5);
  font-size: 0.875rem;
}

/*Credit to: https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
.scroll-shadows {
  /* max-height: 200px; */
  overflow: auto;

  background:
    /* Shadow Cover TOP */ linear-gradient(
        rgb(86, 94, 100) 30%,
        rgba(86, 94, 100, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */
      linear-gradient(rgba(86, 94, 100, 0), rgb(86, 94, 100) 70%) center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
