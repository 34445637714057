html {
  --banner-bg-color: #193a71;

  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.test-page.scroll-container {
  height: 100vh;

  padding: 0% 0% 30% 0%;
  /* padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left); */

  /* display: flex;
    justify-content: center;
    align-items: center; */
  overflow: hidden;
  /* padding-bottom: 12%; */
  position: relative;

  scroll-snap-align: start;
  scroll-snap-stop: always;
  /* box-shadow: inset 0px 0px 2px 2px white; */
}

.test-page .height-test-env {
  height: 100vh;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  /* padding: 20%; */
  display: relative;
}

.test-page .page-container {
  display: block;
  position: relative;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  /* box-shadow: inset 0px 0px 2px 2px green; */
}

.test-page .content-container {
  /* width: 100%; */
  /* max-height: 80vh; */
  /* overflow: hidden; */
  /* display: flex; */
  /* flex-direction: column; */

  /*   
    position: relative;
    max-height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    box-shadow: inset 0px 0px 2px 2px red; */

  width: 100%;
  /* Set the width of the container */
  height: 100%;
  /* Set initial height to 0 for aspect ratio calculation */
  /* padding-bottom: 60%;  */
  /* Set the desired aspect ratio (e.g., 60% for a 3:2 aspect ratio) */
  position: relative;
  /* Create a positioning context for the image */
  /* overflow: hidden;  */
  /* Hide any overflow beyond the container */
  box-shadow: inset 0px 0px 0px 2px rgb(255, 255, 255, 0.5);
}

.test-page .throwball-box {
  width: 100%;
  /* Set the width of the container */
  height: 100%;
  /* Set initial height to 0 for aspect ratio calculation */
  /* padding-bottom: 60%; */
  /* Set the desired aspect ratio (e.g., 60% for a 3:2 aspect ratio) */
  position: relative;
  /* Create a positioning context for the image */
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 2px rgb(255, 255, 255, 0.5);
}

.test-page .throwball-box img {
  /* max-height: 100%; */
  /* box-shadow: inset 0px 0px 2px 2px purple; */
  box-shadow: inset 0px 0px 0px 2px rgb(0, 255, 0, 0.5);

  position: absolute; /* Position the image relative to the container */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  /* Let the browser calculate the width while maintaining aspect ratio */
  height: auto;
  /* Let the browser calculate the height while maintaining aspect ratio */
  max-width: 100%;
  /* Limit the width to the container's width */
  max-height: 100%; /* Limit the height to the container's height */
}

.test-page .img-dim {
  margin-inline: auto;
  box-shadow: inset 0px 0px 0px 4px rgb(255, 0, 0, 1);
  padding: 10%;
}

.test-page .bg-absolute {
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: -1;
  /* box-shadow: inset 0px 20px 10px -10px rgba(0, 0, 0, 0.8); */
  box-shadow: inset 0px 0px 1rem 1rem rgba(0, 0, 0, 0.8);
  /* border-radius: 0%; */
}

.test-page .banner-row {
  position: relative;
  margin-inline: auto;
  margin-top: -100px;
  box-shadow: inset 0px 0px 2px 2px orange;
}

.test-page .banner-box {
  width: 80vw;
  max-width: 300px;
  max-height: 20vh;
  margin-inline: auto;
  position: relative;
}

.test-page .banner-bg {
  background-color: var(--banner-bg-color);
  width: 80vw;
  max-width: 350px;
  filter: drop-shadow(10px 10px 10px #000000);
  position: absolute;
  left: 50%;
}

.test-page .banner-content {
  position: absolute;
  max-height: 20vh;
  filter: drop-shadow(4px 4px 2px #000000);
}

.test-page #madison {
  top: -15%;
  left: -5%;
  /* width: 95%; */
}

.test-page #letter-m {
  top: -15%;
  left: -5%;
}

.test-page .letter-m-transparent {
  position: relative;
  max-height: 20vh;
  max-width: 280px;
}

.test-page .svg-icon-link {
  /* background-color: #e91d00; */
  display: inline-block;
  width: 5em;
  height: 5em;
  padding-top: 5em;
  border-radius: 0.5em;
}

.test-page .svg-icon-link:hover {
  transform: translate(2px, -2px);
}

.test-page .btn {
  line-height: 1em !important;
}

.test-page .btn svg {
  margin-bottom: 0.1em;
  /* margin-left: 0.1em;
    margin-right: 0.1em; */
}

.test-page .icon-button {
  font-size: 2rem;
  line-height: 1rem;
  opacity: 90%;
}

.test-page .icon-button:hover {
  opacity: 100%;
}

.test-page .icon-button svg {
  pointer-events: none;
}

/* --------------- */
/* --------------- */

/* QRCODE MODAL */
/* full screen modal translates from top which does not center the qrcode image. This will scale from 0 to 1 instead. Since the element is full screen, the scale will originate from the center of the screen.*/
.test-page .qrcode-modal.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(0);
}

.test-page .qrcode-modal.modal.show .modal-dialog {
  transform: none;
}

.test-page .qrcode-modal.modal.fade {
  transition: opacity 0.15s linear;
}

.test-page .qrcode-modal.modal.fade:not(.show) {
  opacity: 0;
}
/* END - QRCODE MODAL */

/* --------------- */
/* --------------- */

/* UTILITY */

/* change pointer on clickable icons or images without button or anchor */
.test-page .click-pointer:hover {
  cursor: pointer;
}

/* bootstrap did not have a max height. */
/* use to keep modal image contained on page if full screen and landscape*/
.test-page .max-vh-100 {
  max-height: 100vh;
}

.test-page .handwritten-text {
  font-family: "Caveat", cursive;
}

.test-page .drop-shadow {
  -webkit-filter: drop-shadow(10px 10px 10px #000000);
  filter: drop-shadow(10px 10px 10px #000000);
}
/* END - UTILITY */

.test-page .btn,
.test-page input {
  font-size: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  min-height: auto;
}

.test-page .input-group-text,
.test-page .form-control-color,
.test-page .form-control-sm {
  font-size: 0.75rem;
}

.test-page .form-control-color {
  font-size: 0.75rem;
  max-width: 2.35rem;
}

.test-page .form-check input {
  margin-top: 0.375rem;
}

.test-page .form-switch input {
  font-size: 0.875rem;
  margin-top: 0.375rem;
}
