@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

/* Bootstrap variable overrides */
/* ---------------------------- */

/* Dropdown Colors */
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-light);
  text-decoration: none;
  background-color: var(--bs-gray-600);
}
/* .dropdown-menu {
  max-height: 60vh;
  overflow-y: scroll;
} */

/* FORM RANGE */
.form-range::-webkit-slider-thumb {
  background: var(--bs-gray-500);
  /* box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.25); */
}
.form-range::-moz-range-thumb {
  background: var(--bs-gray-500);
  /* box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.25); */
}
.form-range::-ms-thumb {
  background: var(--bs-gray-500);
  /* box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.25); */
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range:active::-webkit-slider-thumb {
  background-color: var(--bs-gray-500);
  box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.5);
}

.form-range:focus::-webkit-slider-thumb {
  background-color: var(--bs-dark);
  box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.5);
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-light);
  border-color: transparent;
  border-radius: 1rem;
}
/* END FORM RANGE */

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #565e64;
  --bs-nav-link-color: #212529;
  --bs-nav-link-hover-bg: #d2d2d2;
  --bs-nav-link-hover-color: #212529;
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #565e64;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-btn-color: #ebeff5;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.25);
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: #363c41;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

/* -------------------------------- */
/* END Bootstrap variable overrides */

/* Custom color variables */
/* ---------------------- */
.nav-pills {
  --bs-nav-link-bg: #e6e6e6;
}
/* -------------------------- */
/* END Custom color variables */

body {
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik Mono One", sans-serif;
}

.fontCode {
  font-family: "Ubuntu Mono", monospace;
  /* font-size: 0.75rem;
  white-space: pre-wrap; */
}

.small {
  font-weight: 300;
  font-style: italic;
}

/* .cardFooter {
  font-size: 0.65rem;
  line-height: 0.85rem;
} */

.appCard {
  width: 294px;
}

.appCard .cardFooter {
  font-size: 0.65rem;
  line-height: 0.85rem;
}

.favorite-icon {
  font-size: 0.8rem;
}

.svgStepList .input-group-text,
#addSVGStepDropdown {
  min-width: 3rem;
  justify-content: center;
}

.info-icon {
  font-size: 0.5rem !important;
  padding: 2px;
}

/* .span-break {
  width: 100%;
} */

.form-dark .form-control:focus,
.form-dark .form-control:active,
.form-dark .form-check-input:focus {
  border-color: #b1b1b1;
  box-shadow: 0 0 0 0.15rem rgb(165, 165, 165, 0.25);
}

.form-dark .form-check-input:checked {
  background-color: #565e64;
  border-color: #212529;
}

.input-group-color-picker {
  flex-wrap: nowrap;
}

.input-group > .form-control.form-control-color {
  min-width: 42px;
  max-width: 42px;
}

/* nav pill styles */
.nav-pills .nav-link {
  background-color: var(--bs-nav-link-bg);
}

.nav-link:focus,
.nav-link:hover {
  background-color: var(--bs-nav-link-hover-bg);
}

/* END nav pill styles */

/* Media Queries */
/* @media (max-width: 768px) {
  .form-smallText > * {
    font-size: 0.75rem;
  }
} */
