/*  */
body {
  font-size: 0.875rem;
}

h5 {
  font-size: 1rem;
}

.fontCodePill {
  font-family: "Ubuntu Mono", monospace;
  font-size: 0.8rem;
  padding: 0.15rem 0.3rem 0.1rem 0.3rem;
  border-radius: 0.25rem;
  /* background-color: #6c757d; */
}

.fontCodePillClose {
  font-family: "Ubuntu Mono", monospace;
  font-size: 0.5rem;
  /* white-space: pre-wrap; */
}

/* Checkbox checked and focus colors */

/* FORM - START */
/* Adding size="sm" to input group was overriding fontCode size .75rem to .875rem. Since app will have numerous form inputs, below is the bootstrap style changes to make the form elements small and using the font size .75rem as desired.*/
.input-group .form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

/* Checkbox checked and focus switch svg and colors */
/* updated colors within the svg fill */
.form-switch .form-check-input:checked:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 1%29%27/%3e%3c/svg%3e");
}

.form-checkbox input:checked {
  background-color: #212529;
  border-color: #212529;
}

.form-checkbox input:focus {
  border-color: #6c757d;
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.25);
}

/* Checkbox checked and focus colors */
.form-control:focus {
  border-color: #6c757d;
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.25);
}

/* This attempts to update some of the colors of the browser calendar and time input. Unable to customize all colors. */
/* input[type="date" i] {
  background-color: red;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: red;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  background-color: blue;
}

input[type="date"]::-webkit-datetime-edit-calendar {
  background-color: blue;
} */

.dropdown-menu {
  max-height: 60vh;
  overflow-y: scroll;
  z-index: 1019;
}

/* When focus is on a dropdown item, this overwrites the white border */
.dropdown-item:focus-visible {
  outline: #212529 solid 1px;
}

/* --- CUSTOM SCROLL BAR - START --- */
/* width */
.dropdown-menu::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--bs-gray-900);

  background: var(--bs-secondary);
}

/* Handle */
.dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--bs-gray-800);
  border-radius: 1.25px;
}

/* Handle on hover */
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray-900);
}
/* --- CUSTOM SCROLL BAR - END --- */

.input-group-border {
  border: 1px solid #1a1a1a;
}

.info-button:hover {
  stroke: white;
  stroke-width: 0.03em;
}

/* FORM - END */

/* ACCORDION - START */
/* opted to use a nested accordion. Also wanted the expand icon to span the entire header, and to keep the expanded content ends aligned. This is a custom accordion button */
.option-accordion {
  padding: 0;
  margin-bottom: 1rem;
  border: none;
  border-width: 0;
}
.option-accordion button {
  padding: 0;
  margin: 0;
}
.option-accordion button:focus {
  border: none;
  box-shadow: none;
}
/* .option-accordion button::after {
  background-image: none;
  margin: 0;
  width: 0;
  height: 0;
  background-size: 0;
} */
.option-accordion table {
  margin: 0;
}

.option-accordion .accordion-body {
  padding: 0;
  display: flex;
  background-color: var(--bs-accordion-active-bg);
}

.option-accordion .accordion-body::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}
/* ACCORDION - END */

/* sticky box is to remain with generic css for potential reuse with different content */
.sticky-box {
  position: sticky;
  top: 0px;
  z-index: 1016;
  visibility: visible;
}

/* Contents of the sticky box are date box. Sticky box can be reused for other content, ideally */
/* --- DATE BOX - START--- */
.date-box {
  background-color: rgb(33, 37, 41);
  line-height: 0.875rem;
  font-size: 0.8rem;

  /* if formatting when collapsed, set the default */
  font-size: 1rem;
}

.date-box .badge {
  padding: 0.125em 0.375em 0.125em 0.375em;
  border-radius: 0.25rem;
  font-weight: normal;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 0.875rem;
  line-height: 0.75rem;

  /* if formatting when collapsed, set the default */
  /* font-size: 0.9rem; */
}

/* if formatting when collapsed, set the collapsed values */
/* .date-box .collapsed {
  font-size: 0.875rem;
  line-height: 0.75rem;
}

.date-box .collapsed .badge {
  font-size: 0.8rem;
} */
/* --- DATE BOX - END--- */

/* --- COLLAPSE BUTTON - START --- */
/* .collapse-button {
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  background-color: #212529;
  z-index: 1017;
  padding-left: 1px;
  padding-right: 1px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  border-radius: 0.125rem;
} */

.collapse-button {
  z-index: 1;
}

.collapse-button:hover {
  background-color: #424649 !important;
}

/* Transition - collapse arrow flips */
.arrow-flip-up {
  padding-bottom: 0.08em;
  padding-top: 0.08em;
  margin-top: 0.125em;
  margin-bottom: 0.125em;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: 600ms;
}
.arrow-flip-down {
  padding-bottom: 0.08em;
  padding-top: 0.08em;
  margin-top: 0.125em;
  margin-bottom: 0.125em;
  cursor: pointer;
  transform: rotate(0deg);
  transition: 600ms;
}

/* --- COLLAPSE BUTTON - START --- */

/* --- ICON BUTTONS - START --- */
.icon-back {
  padding: 0.125em;
  font-size: 0.815em;
  color: #000;
  /* background-color: #f8f9fa; */
  border-color: #f8f9fa;
  border: 1px solid white;
  border-radius: 0.15rem;

  display: flex;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.icon-back svg {
  width: 0.825em;
  height: 0.825em;
}

.icon-button {
  padding: 0.2em;
  font-size: 0.815em;
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  aspect-ratio: 1 / 1;
}

.icon-button:hover {
  color: #000;
  background-color: #d3d4d5;
  border-color: #c6c7c8;
  /*   
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8; */
}

.add-button svg {
  height: 0.875rem;
  font-size: 0.75rem;
  fill: rgba(248, 249, 250, 0.75);
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-button svg:hover {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 0.75px;
  /* cursor: pointer; */
}

.incompatible svg {
  height: 0.875rem;
  font-size: 0.75rem;
  fill: rgba(248, 249, 250, 0.5);
  stroke: none;
  stroke-width: 0px;
}

/* .incompatible.add-button:hover {
  pointer-events: none;
  cursor: default;
  fill: rgba(248, 249, 250, 0.25);
  stroke: none;
  stroke-width: 0px;
} */

/* --- ICON BUTTONS - END --- */

/* object code adds formatting to assist with text that should display indentations like an object or JSON code */
.objectCode pre {
  margin: 0;
  padding: 0.125em;
  text-align: start;
  overflow: inherit;

  /* wraps text within a pre element */
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.objectCode svg {
  padding-bottom: 0.125em;
  margin-right: 0.125em;
}

.objectCode svg:hover {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 0.75px;
}

.copy-icon {
  fill: rgba(255, 255, 255, 0.75);
}

.copy-icon:hover {
  fill: rgba(255, 255, 255, 1);
}

/* For nested accordions, the font-size is re-applied to the button by bootstrap.  */
.accordion-item .accordion-button {
  font-size: 0.875rem;
}

.accordion-item table th {
  min-width: 3rem;
}
